import styles from './Contact.module.css';

const Contact = () => {
  return (
    <div className={styles.Contact}>
      <header>
        <h1>CONTACT</h1>
      </header>
      <main>
        <h3>BOOKINGS</h3>
        <div className={styles.ContactInfo}>
          <h4>EU/UK Booking Agent:</h4>
          <span>Haydn Britland - NMC Live</span>
          <br/>
          <div className={styles.Email}>
            <img src="https://img.icons8.com/ios-glyphs/30/FFFFFF/secured-letter--v1.png" alt="email icon"/>
            <span>haydn@northernmusic.co.uk</span>
          </div>
        </div>
        <div className={styles.ContactInfo}>
          <h4>North American Booking Agent:</h4>
          <span>Dan Rozenblum - 33&West </span>
          <br/>
          <div className={styles.Email}>
            <img src="https://img.icons8.com/ios-glyphs/30/FFFFFF/secured-letter--v1.png" alt="email icon"/>
            <span>dan@33andwest.com</span>
          </div>
        </div>
        <hr/>
        <h3>Management/Rest of World Bookings</h3>
        <div className={styles.ContactInfo}>
          <h4>Tim charles - Welkin Entertainment</h4>
          <div className={styles.Email}>
            <img src="https://img.icons8.com/ios-glyphs/30/FFFFFF/secured-letter--v1.png" alt="email icon"/>
            <span>tim.charles@welkinentertainment.com</span>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Contact;