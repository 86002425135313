import styles from './Navbar.module.css';

import { Cross as Hamburger} from 'hamburger-react';

const Navbar = ({open, toggleMenu}) => {

  return (
    <nav className={styles.Navbar}>
        <Hamburger toggled={open} toggle={toggleMenu} color="#fff"/>
        <div className={styles.Logo}>
          <a href="/">
            <img src="https://i.imgur.com/XUQCAig.png" alt="Ne Obliviscaris logo" width="150px"/>
          </a>
        </div>
        <div className={styles.Socials}>
          <a href="https://www.facebook.com/NeObliviscarisBand/">
            <img src="https://img.icons8.com/ios-filled/50/FFFFFF/facebook-new.png" alt="facebook icon"/>
          </a>
          <a href="https://www.instagram.com/neobliviscarisofficial/?hl=en">
            <img src="https://img.icons8.com/ios-filled/50/FFFFFF/instagram-new--v1.png" alt="Instagram icon"/>
          </a>
          <a href="https://twitter.com/neobliviscaris3">
            <img src="https://img.icons8.com/ios-filled/50/FFFFFF/twitter-squared.png" alt="twitter icon"/>
          </a>
          <a href="https://www.youtube.com/neobliviscaris">
            <img src="https://img.icons8.com/material-rounded/48/FFFFFF/youtube-play.png" alt="youtube icon" style={{ width: "30px" }}/>
          </a>
          <a href="https://www.patreon.com/neobliviscaris">
            <img src="https://i.imgur.com/mRLvuN9.png" alt="Patreon icon" style={{ width: "22px"}}/>
          </a>
        </div>
    </nav>
  )
}

export default Navbar