import { doc, getDoc } from 'firebase/firestore';

import { db } from '../../firebase';
import { useState, useEffect } from 'react';

import styles from './Music.module.css';

const links = [
    { service: "spotify", url:  "https://open.spotify.com/album/6u0sO15h0hLRfNJmbmqJD1", icon: "https://i.imgur.com/uDODuLU.png" },
    { service: "apple music", url: "https://music.apple.com/us/album/equus/1651271436?i=1651271443", icon: "https://i.imgur.com/1VPqB0c.png" },
    { service: "bandcamp", url: "https://neobliviscarissom.bandcamp.com/track/equus", icon: "https://i.imgur.com/BCWuSBy.png" },
    { service: "deezer", url: "https://www.deezer.com/us/album/363987197", icon: "https://i.imgur.com/aP6lpai.png" },
    { service: "tidal", url: "https://tidal.com/browse/album/252833160", icon: "https://i.imgur.com/oXkh20v.png" },
    { service: "Amazon Music", url: "https://music.amazon.com/albums/B0BKCBRQ2K?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_Idzs0KXN99cw9gq2EgFPxJpHj&trackAsin=B0BKBYRCP6", icon: "https://i.imgur.com/PuCqlsK.png"  },
    { service: "soundcloud", url: "https://soundcloud.com/neobliviscaris/equus", icon: "https://i.imgur.com/PuCqlsK.png"  }
];

const singleStatusDocRef = doc(db, "single-streaming-status/equus");

const EquusMusic = () => {
  const [singleActive, setSingleStatus] = useState(false);

  useEffect(() => {
    getSingleState()
    .then((singleState) => {
      setSingleStatus(singleState)
    })
  }, [setSingleStatus])

  async function getSingleState() {
    const docSnap = await getDoc(singleStatusDocRef);

    if (docSnap.exists()) {
        const { active } = docSnap.data();
        return active;
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  };
  return (
    <div className={styles.Music}>
        <div className={styles.Background}></div>
        <div className={styles.Container}>
            <header>
                <img src="https://i.imgur.com/WbmjMaO.jpg" alt="Ne Obliviscaris Exul album cover" width="100%"/>
            </header>
            <main>
                <a style={{ display: singleActive? "block" : "none" }} href="https://www.youtube.com/watch?v=Bftqq_BqQHI" target="_blank" rel="noreferrer">
                  <div className={styles.Link}>
                    <span>YOUTUBE</span>
                  </div>
                </a>
                {
                  links.map(({service, url, icon}, index) => (
                    <a href={url} target="_blank" rel="noreferrer">
                      <div key={index} className={styles.Link}>
                        <span>{service}</span>
                      </div>
                    </a>
                  ))
                }
            </main>
        </div>
    </div>
  )
}

export default EquusMusic;