import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { query, collection, orderBy, getDocs } from "firebase/firestore";

import styles from "./Tour.module.css";

const Tour = ({ headerImg, tourPath, name, flyer }) => {
  const [tourDates, setTourDates] = useState(null);
  useEffect(() => {
    getTourDates();
  }, [setTourDates]);

  async function getTourDates() {
    const tourDatesCollectionRef = collection(db, tourPath);
    let tourArray = [];
    const q = query(tourDatesCollectionRef, orderBy("order"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const { date, city, venue, link, vip, order, soldOut } = doc.data();
      tourArray.push({
        date: date,
        city: city,
        venue: venue,
        link: link,
        vip: vip,
        order: order,
        soldOut: soldOut,
      });
    });
    setTourDates([...tourArray]);
  }
  return (
    <div className={styles.Tour}>
      <header
        style={{
          backgroundImage: headerImg
            ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1)), url(${headerImg})`
            : "",
          marginBottom: headerImg ? "2rem" : "0",
          height: headerImg ? "300px" : "auto",
        }}
      >
        {/* <h1>{name.toUpperCase().split("TOUR")[0] + " TOUR"}</h1>
          <h3>{name.toUpperCase().split("TOUR")[1]}</h3> */}
        <h1>{name && name.toUpperCase()}</h1>
        {/* <h1>UPCOMING TOURS</h1> */}
      </header>
      <main>
        <div className={styles.TourDates}>
          {tourDates && tourDates.length > 0
            ? tourDates.map(
                ({ date, city, venue, link, soldOut, vip }, index) => (
                  <div key={index} className={styles.TourDate}>
                    <span>
                      <strong>
                        {date.split(" ")[0]}
                        <br />
                        {date.split(" ")[1]}
                      </strong>
                    </span>
                    <div className={styles.Location}>
                      <span style={{ color: "#7BB6BD" }}>
                        <strong>{city}</strong>
                      </span>
                      <br />
                      <span>{venue}</span>
                    </div>
                    <div className={styles.ctaContainer}>
                      {
                        link.length > 1 ?
                        <a
                        href={!soldOut ? link : ""}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className={!soldOut ? "cta" : "disabled"}>
                          {soldOut ? "SOLD OUT" : link.length < 1 ? "TICKETS ON SALE SOON" : "BUY TICKETS"}
                        </button>
                      </a>
                      :
                      <button className="disabled">TICKETS ON SALE SOON</button>
                      }
                      {vip?.available && (
                        <a
                          href={!soldOut ? vip?.link : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button
                            className={
                              vip?.soldOut || vip?.link?.length < 1
                                ? "disabled"
                                : "vip"
                            }
                          >
                            {vip?.soldOut
                              ? "VIP SOLD OUT"
                              : vip?.link?.length < 1
                              ? "VIP UPGRADES ON SALE SOON"
                              : "VIP PACKAGE"}
                          </button>
                        </a>
                      )}
                    </div>
                  </div>
                )
              )
            : null}
          {flyer && <img src={flyer} alt="tour flyer" width="100%" />}
        </div>
      </main>
    </div>
  );
};

export default Tour;
