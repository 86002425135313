import React, { useEffect, useState } from "react";
import { getTourDates } from "../../lib/functions.ts";

import styles from "./Tours.module.css";

const Tours = () => {
  const [euTourDates, setEuTourDates] = useState();
  const [latinTourDates, setLatinTourDates] = useState();

  useEffect(() => {
    getTourDates("eu-uk-tour-2024", setEuTourDates);
    getTourDates("latin-america-tour-2024", setLatinTourDates);
  }, []);

  return (
    <div className={styles.Tours}>
      <header>
        <h1>TOUR DATES</h1>
      </header>
      <div className={styles.Tour}>
        <header>
          <h1>EU / UK TOUR 2024</h1>
          <img
            width="100%"
            src="https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/NEO_TOUR_EU_2024_OSN_FB.jpg?alt=media&token=56b4730b-a737-4b9a-8efb-c30679be80de"
          />
        </header>
        <main>
          <div className={styles.TourDates}>
            {euTourDates && euTourDates.length > 0
              ? euTourDates.map(
                  ({ date, city, venue, link, soldOut, vip }, index) => (
                    <div key={index} className={styles.TourDate}>
                      <span>
                        <strong>
                          {date.split(" ")[0]}
                          <br />
                          {date.split(" ")[1]}
                        </strong>
                      </span>
                      <div className={styles.Location}>
                        <span style={{ color: "#7BB6BD" }}>
                          <strong>{city}</strong>
                        </span>
                        <br />
                        <span>{venue}</span>
                      </div>
                      <div className={styles.ctaContainer}>
                        {link.length > 1 ? (
                          <a
                            href={!soldOut ? link : ""}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button className={!soldOut ? "cta" : "disabled"}>
                              {soldOut
                                ? "SOLD OUT"
                                : link.length < 1
                                ? "TICKETS ON SALE SOON"
                                : "BUY TICKETS"}
                            </button>
                          </a>
                        ) : (
                          <button className="disabled">
                            TICKETS ON SALE SOON
                          </button>
                        )}
                        {vip?.available && (
                          <a
                            href={!soldOut ? vip?.link : ""}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button
                              className={
                                vip?.soldOut || vip?.link?.length < 1
                                  ? "disabled"
                                  : "vip"
                              }
                            >
                              {vip?.soldOut
                                ? "VIP SOLD OUT"
                                : vip?.link?.length < 1
                                ? "VIP UPGRADES ON SALE SOON"
                                : "VIP PACKAGE"}
                            </button>
                          </a>
                        )}
                      </div>
                    </div>
                  )
                )
              : null}
            {/* {flyer && <img src={flyer} alt="tour flyer" width="100%" />} */}
          </div>
        </main>
      </div>
      <div className={styles.Tour}>
        <header>
        <h1>LATIN AMERICA TOUR 2024</h1>
          <img
            width="100%"
            src="https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/NEOBLISCARIS%20LATAM%202024%20TALENTNATION-03%20Banner.jpg?alt=media&token=a5a17a72-0533-440b-abb6-c34c3531e034"
          />
        </header>
        <main>
          <div className={styles.TourDates}>
            {latinTourDates && latinTourDates.length > 0
              ? latinTourDates.map(
                  ({ date, city, venue, link, soldOut, vip }, index) => (
                    <div key={index} className={styles.TourDate}>
                      <span>
                        <strong>
                          {date.split(" ")[0]}
                          <br />
                          {date.split(" ")[1]}
                        </strong>
                      </span>
                      <div className={styles.Location}>
                        <span style={{ color: "#7BB6BD" }}>
                          <strong>{city}</strong>
                        </span>
                        <br />
                        <span>{venue}</span>
                      </div>
                      <div className={styles.ctaContainer}>
                        {link.length > 1 ? (
                          <a
                            href={!soldOut ? link : ""}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button className={!soldOut ? "cta" : "disabled"}>
                              {soldOut
                                ? "SOLD OUT"
                                : link.length < 1
                                ? "TICKETS ON SALE SOON"
                                : "BUY TICKETS"}
                            </button>
                          </a>
                        ) : (
                          <button className="disabled">
                            TICKETS ON SALE SOON
                          </button>
                        )}
                        {vip?.available && (
                          <a
                            href={!soldOut ? vip?.link : ""}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button
                              className={
                                vip?.soldOut || vip?.link?.length < 1
                                  ? "disabled"
                                  : "vip"
                              }
                            >
                              {vip?.soldOut
                                ? "VIP SOLD OUT"
                                : vip?.link?.length < 1
                                ? "VIP UPGRADES ON SALE SOON"
                                : "VIP PACKAGE"}
                            </button>
                          </a>
                        )}
                      </div>
                    </div>
                  )
                )
              : null}
            {/* {flyer && <img src={flyer} alt="tour flyer" width="100%" />} */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Tours;
