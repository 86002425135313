import React, { Dispatch, FC, SetStateAction } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

interface AlbumAnnouncementProps {
  setOpenShopModal?: Dispatch<SetStateAction<boolean>>;
}

const AlbumAnnouncement:FC<AlbumAnnouncementProps> = ({setOpenShopModal}) => {
  const navigate = useNavigate();
  return (
    <StyledContainer>
      <ImageColumn>
        <img src="https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/Citadel%20Merch.png?alt=media&token=c34d1ee8-b793-4697-a24c-4594127bbec6" />
      </ImageColumn>
      <TextColumn>
        <h1>
          <span style={{ color: "#7bb6bd", fontWeight: "bold" }}>CITADEL</span>{" "}
          <br />
          10TH ANNIVERSARY <br />
          RE-MASTER
        </h1>
        <ButtonsContainer>
          <button onClick={() => navigate("/music")}>STREAM ALBUM</button>
          <button onClick={() => setOpenShopModal?.(true)}>PRE-ORDER</button>
        </ButtonsContainer>
      </TextColumn>
    </StyledContainer>
  );
};

export default AlbumAnnouncement;

const StyledContainer = styled("div")(() => ({
  padding: "24px 8.3%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  alignItems: "center",
  backgroundImage:
    "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url('https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/CITADEL_2000x2000-min%203-min.png?alt=media&token=f8e76628-5db4-4bc5-b027-0ebf11e1a5a1')",
  backgroundSize: "cover",
  backgroundPosition: "center",

  [`@media (max-width: 1030px)`]: {
    gridTemplateColumns: "1fr",
  },
}));

const TextColumn = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: "16px",

  ["h1"]: {
    fontSize: "3vw",
    fontWeight: "400",
  },
  ["h2"]: {
    fontSize: "1.5vw",
    fontWeight: "400",
  },

  ["button"]: {
    border: `2px solid #7bb6bd`,
    backgroundColor: "transparent",
    color: "#7bb6bd",
    width: "100%",
    padding: "8px 16px",
  },

  ["button:hover"]: {
    border: `2px solid #7bb6bd`,
    backgroundColor: "#7bb6bd",
    color: "#fff",
    width: "100%",
    padding: "8px 16px",
  },

  [`@media (max-width: 1030px)`]: {
    h1: {
      fontSize: "4vw",
      fontWeight: "400",
    },
    h2: {
      fontSize: "3vw",
      fontWeight: "400",
    },
  },
}));

const ButtonsContainer = styled("div")(() => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: "8px",
  rowGap: "16px",
}));

const ImageColumn = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",

  ["img"]: {
    width: "100%",
  },

  [`@media (max-width: 1030px)`]: {
    img: {
      width: "70%",
    },
  },
}));

