import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import styles from './Menu.module.css';

const Menu = ({open}) => {
  useEffect(() => {
    return clearAllBodyScrollLocks();
  }, [])
  open? disableBodyScroll(document.body) : enableBodyScroll(document.body);
  return (
    <div className={styles.Menu} style={{display: open? 'flex' : 'none'}}>
        <a href="/">HOME</a>
        <a href="/?shopModal=true">MERCHANDISE</a>
        <a href="/tours">TOUR DATES</a>
        <a href="/music">MUSIC</a>
        <a href="https://www.patreon.com/neobliviscaris/posts" target="_blank" rel="noreferrer">PATREON</a>
        <a href="/contact">CONTACT</a>
        <div className={styles.Socials}>
          <a href="https://www.facebook.com/NeObliviscarisBand/">
            <img src="https://img.icons8.com/ios-filled/50/FFFFFF/facebook-new.png" alt="facebook icon"/>
          </a>
          <a href="https://www.instagram.com/neobliviscarisofficial/?hl=en">
            <img src="https://img.icons8.com/ios-filled/50/FFFFFF/instagram-new--v1.png" alt="Instagram icon"/>
          </a>
          <a href="https://twitter.com/neobliviscaris3">
            <img src="https://img.icons8.com/ios-filled/50/FFFFFF/twitter-squared.png" alt="twitter icon"/>
          </a>
          <a href="https://www.youtube.com/neobliviscaris">
            <img src="https://img.icons8.com/material-rounded/48/FFFFFF/youtube-play.png" alt="youtube icon" style={{ width: "36px" }}/>
          </a>
          <a href="https://www.patreon.com/neobliviscaris">
            <img src="https://i.imgur.com/mRLvuN9.png" alt="Patreon icon" style={{ width: "26px"}}/>
          </a>
        </div>
    </div>
  )
}

export default Menu