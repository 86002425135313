import { Dispatch, SetStateAction } from "react";
import { db } from "../firebase";
import { query, collection, orderBy, getDocs } from "firebase/firestore";

export async function getTourDates(tourPath: string, setTourDates: Dispatch<SetStateAction<any[]>>) {
    const tourDatesCollectionRef = collection(db, tourPath);
    let tourArray: any[] = [];
    const q = query(tourDatesCollectionRef, orderBy("order"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const { date, city, venue, link, vip, order, soldOut } = doc.data();
        tourArray.push({
            date: date,
            city: city,
            venue: venue,
            link: link,
            vip: vip,
            order: order,
            soldOut: soldOut,
        });
    });
    setTourDates([...tourArray]);
}