import styles from "./App.module.css";
import { doc, getDoc } from "firebase/firestore";

import { db } from "./firebase";

import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  redirect,
} from "react-router-dom";

// Pages
import Home from "./pages/Home/Home.";
import Tour from "./pages/Tour/Tour";
import Music from "./pages/Music/Music";
import EquusMusic from "./pages/Music/EquusMusic";
import GraalMusic from "./pages/Music/GraalMusic";
import Contact from "./pages/Contact/Contact";
import Tours from "./pages/Tours/Tours";

// Components
import Navbar from "./components/Navbar";
import Menu from "./components/Menu/Menu";


const euTourStatusDocRef = doc(db, "tour-status/eu-uk-tour-2024");

function App() {
  const [loading, setLoadingState] = useState(true);
  const [menuOpen, setMenuState] = useState(false);
  const [euUkTourState, setEuUkTourState] = useState(false);

  useEffect(() => {
    setLoadingState(false);
  }, [setLoadingState]);

  async function getEuUkTourState() {
    const docSnap = await getDoc(euTourStatusDocRef);

    if (docSnap.exists()) {
      const { active } = docSnap.data();
      return active;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  useEffect(() => {
    getEuUkTourState().then((res) => setEuUkTourState(res));
  }, []);

  if (loading) return "Loading...";

  return (
    <Router>
      <div className={styles.App}>
        <Navbar open={menuOpen} toggleMenu={setMenuState} />
        <Menu open={menuOpen} />
        <div className={styles.Body}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/tours/exul-na" element={<Tour headerImg="https://i.imgur.com/G13xpAA.png" tourPath="us-oct-2023-exul-tour-links" name="USA / CAN TOUR OCTOBER 2023" flyer="https://i.ibb.co/ykxvrVn/kqiH-Uyx.jpg"/>}/> */}
            {euUkTourState && (
              <Route
                path="/tours/eu-uk-tour-2024"
                element={
                  <Tour
                    tourPath="eu-uk-tour-2024"
                    name="EU / UK Tour 2024"
                    headerImg="https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/Blank%20Banner.jpg?alt=media&token=6d1db147-3695-418c-849b-9cf858f98cec"
                    flyer="https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/NEO_TOUR_EU_2024_OSN.jpg?alt=media&token=d867ad33-037d-444e-b6dc-e18f4feb80f9"
                  />
                }
              />
            )}
            <Route
              path="/tours/summer-festivals-2024"
              element={
                <Tour
                  tourPath="tours-2024"
                  name="SUMMER FESTIVALS 2024"
                  headerImg="https://i.ibb.co/9hrccbY/Ne-O-Exul-Cover-2000x2000-1-5.png"
                  flyer="https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/NEO_Festivals_2024(1).jpg?alt=media&token=e7e05000-1535-4943-b1a2-9bf3e28418ff"
                />
              }
            />
            <Route path="tours/latin-america-tour-2024" element={
              <Tour 
              tourPath="latin-america-tour-2024"
              name="LATIN AMERICA TOUR 2024"
              headerImg="https://i.ibb.co/9hrccbY/Ne-O-Exul-Cover-2000x2000-1-5.png"
              flyer="https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/NEOBLISCARIS%20LATAM%202024%20TALENTNATION-02%20Stories-min.jpg?alt=media&token=3074ef3e-44d3-4559-9e07-0e9c9b24b2da"
              />
            }/>
            <Route path="/tours" element={<Tours />}/>
            <Route path="/music" element={<Music />} />
            <Route path="/music/single/equus" element={<EquusMusic />} />
            <Route path="/music/single/graal" element={<GraalMusic />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <footer>
          <span>Copyright Ⓒ Ne Obliviscaris {new Date().getFullYear()}</span>
          <br />
          <span>Desgined by Avintha Fonseka</span>
        </footer>
      </div>
    </Router>
  );
}

export default App;
