import { doc, getDoc } from 'firebase/firestore';

import { db } from '../../firebase';
import { useState, useEffect } from 'react';

import styles from './Music.module.css';

const links = [
    { service: "spotify", url:  "https://open.spotify.com/artist/5kbidtcpyRRMdAQUnI1BG4", icon: "https://i.imgur.com/uDODuLU.png" },
    { service: "apple music", url: "https://music.apple.com/us/artist/ne-obliviscaris/523723345", icon: "https://i.imgur.com/1VPqB0c.png" },
    { service: "bandcamp", url: "https://neobliviscarissom.bandcamp.com", icon: "https://i.imgur.com/BCWuSBy.png" },
    { service: "deezer", url: "https://www.deezer.com/us/artist/2488591", icon: "https://i.imgur.com/aP6lpai.png" },
    { service: "tidal", url: "https://tidal.com/browse/artist/4611534", icon: "https://i.imgur.com/oXkh20v.png" },
    { service: "Amazon Music", url: "https://music.amazon.com/artists/B0082JZBW8/ne-obliviscaris", icon: "https://i.imgur.com/PuCqlsK.png"  },
    { service: "soundcloud", url: "https://soundcloud.com/neobliviscaris", icon: "https://i.imgur.com/PuCqlsK.png"  }
];

const singleStatusDocRef = doc(db, "single-streaming-status/graal");

const Music = () => {
  const [singleActive, setSingleStatus] = useState(false);

  useEffect(() => {
    getSingleState()
    .then((singleState) => {
      setSingleStatus(singleState)
    })
  }, [setSingleStatus])

  async function getSingleState() {
    const docSnap = await getDoc(singleStatusDocRef);

    if (docSnap.exists()) {
        const { active } = docSnap.data();
        return active;
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  };
  return (
    <div className={styles.Music}>
        <div className={styles.Background}></div>
        <div className={styles.Container}>
            <header>
                <img src="https://firebasestorage.googleapis.com/v0/b/neo-admin-49e6f.appspot.com/o/CITADEL_2000x2000-min.jpg?alt=media&token=3e761538-2dfc-43ae-aa1e-b4d4d933144f" alt="Ne Obliviscaris Citadel remastered album cover" width="100%"/>
            </header>
            <main>
                {/* <a style={{ display: singleActive? "block" : "none" }} href="https://youtu.be/WAnq3cAhqDA" target="_blank" rel="noreferrer">
                  <div className={styles.Link}>
                    <span>YOUTUBE - 'GRAAL' MUSIC VIDEO</span>
                  </div>
                </a> */}
                {
                  links.map(({service, url, icon}, index) => (
                    <a href={url} target="_blank" rel="noreferrer">
                      <div key={index} className={styles.Link}>
                        {/* <img src={icon} alt="service"/> */}
                        <span>{service}</span>
                      </div>
                    </a>
                  ))
                }
            </main>
        </div>
    </div>
  )
}

export default Music;