import React from 'react';
import styles from './Modal.module.css';
import PrimaryButton from '../Buttons/PrimaryButton.tsx';

const Modal = ({children, open, setOpen}) => {
  return (
    <div className={styles.Backdrop} style={{ display: open? 'block' : 'none' }}>
    <div className={styles.Modal}>
        <div style={{ width: "100%", textAlign: "right" }}>
        <PrimaryButton onClick={() => setOpen(false)}>X</PrimaryButton>
        </div>
        {children}
    </div>
    </div>
  )
}

export default Modal