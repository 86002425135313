import styles from "./Home.module.css";
import { doc, getDoc } from "firebase/firestore";
import styled from "styled-components";

import { db } from "../../firebase";
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import AlbumAnnouncement from "../../components/AlbumAnnouncement/AlbumAnnouncement.tsx";
import Modal from "../../components/Modal/Modal.jsx";
import PrimaryButton from "../../components/Buttons/PrimaryButton.tsx";

// const singleStatusDocRef = doc(db, "single-streaming-status/graal");
const euTourStatusDocRef = doc(db, "tour-status/eu-uk-tour-2024");

function Home() {
  const [openShopModal, setOpenShopModal] = useState(false);
  // const [singleActive, setSingleStatus] = useState(false);
  const [euUkTourState, setEuUkTourState] = useState(false);
  // const [viewTourDates, setViewTourDates] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  // function scrollToTop() {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth", // This creates a smooth scroll effect
  //   });
  // }

  const navigate = useNavigate();

  const tourRef = useRef(null);

  // useEffect(() => {
  //   getSingleState().then((singleState) => {
  //     setSingleStatus(singleState);
  //   });
  // }, [setSingleStatus]);

  useEffect(() => {
    if (searchParams.get("sc") === "tour" && tourRef.current) {
      tourRef.current.scrollIntoView();
    }

    if (searchParams.get("shopModal") === "true") {
      setOpenShopModal(true);
    }
  }, [searchParams]);

  // async function getSingleState() {
  //   const docSnap = await getDoc(singleStatusDocRef);

  //   if (docSnap.exists()) {
  //     const { active } = docSnap.data();
  //     return active;
  //   } else {
  //     // doc.data() will be undefined in this case
  //     console.log("No such document!");
  //   }
  // }

  async function getEuUkTourState() {
    const docSnap = await getDoc(euTourStatusDocRef);

    if (docSnap.exists()) {
      const { active } = docSnap.data();
      return active;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  useEffect(() => {
    getEuUkTourState().then((res) => setEuUkTourState(res));
  }, []);

  return (
    <div className={styles.Home}>
      <Modal open={openShopModal} setOpen={setOpenShopModal}>
        <StoreOptionsSelect>
          <a
            href="https://jsrdirect.com/collections/ne-obliviscaris"
            target="_blank"
            rel="noreferrer"
          >
            <PrimaryButton>SHOP NORTH AMERICAN STORE</PrimaryButton>
          </a>
          <a
          href="https://artistfirst.com.au/collections/ne-obliviscaris"
          target="_blank"
          rel="noreferrer"
        >
          <PrimaryButton>SHOP AUSTRALIAN STORE</PrimaryButton>
        </a>
        <a
          href="https://www.impericon.com/de/ne-obliviscaris.html"
          target="_blank"
          rel="noreferrer"
        >
          <PrimaryButton>SHOP EUROPEAN STORE</PrimaryButton>
        </a>
        </StoreOptionsSelect>
      </Modal>
      <AlbumAnnouncement setOpenShopModal={setOpenShopModal} />
      <section className={styles.EuUkTour} style={{ height: "auto" }}>
        <div className={styles.Text}>
          <h1>TOUR DATES</h1>
          <br />
          {/* <small>WITH SPECIAL GUESTS</small>
          <br /> <br />
          <small>OCT - NOV 2024</small>
          <br /> <br />
          <small>
            PERFORMING BOTH{" "}
            <strong style={{ color: "#7BB6BD" }}>
              <i>EXUL</i>
            </strong>{" "}
            AND{" "}
            <strong style={{ color: "#7BB6BD" }}>
              <i>CITADEL</i>
            </strong>{" "}
            ALBUMS
            <br /> IN FULL
          </small>
          <br /> <br /> */}
          {/* <div style={{ maxWidth: "300px" }}>
                <button
                  className="cta"
                  onClick={() => {
                    navigate("/tours/eu-uk-2024");
                  }}
                >
                  GET TICKETS
                </button>
              </div> */}
          <div style={{ width: "300px", marginBottom: "1rem" }}>
            <button
              className="cta"
              onClick={() => navigate("/tours/eu-uk-tour-2024")}
            >
              EU / UK TOUR 2024
            </button>
          </div>
          <div style={{ width: "300px", marginBottom: "1rem" }}>
            <button
              className="cta"
              onClick={() => navigate("/tours/latin-america-tour-2024")}
            >
              LATIN AMERICA TOUR 2024
            </button>
          </div>
        </div>
      </section>
      <header
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.9), rgba(77, 90, 98, 0.99)), url('https://i.imgur.com/WbmjMaO.jpg')",
        }}
      >
        <div className={styles.Text}>
          <div>
            <img
              src="https://i.imgur.com/XUQCAig.png"
              alt="Ne Obliviscaris logo"
              width="60%"
            />
            <h3>
              NEW ALBUM - <span>'EXUL'</span>
            </h3>
            <br />
            <h1>OUT NOW!</h1>
            <br />
          </div>
          <div>
            <img
              src="https://i.imgur.com/GvHWmU4.png"
              width="100%"
              alt="some ne obliviscaris merchandise"
            />
          </div>
        </div>
        <div>
          <a href="https://orcd.co/neoexul" target="_blank" rel="noreferrer">
            <button className={styles.LightButton}>STREAM ALBUM</button>
          </a>
        </div>
      </header>
      <div className={styles.PreOrder} id="pre-order">
        <a
          href="https://jsrdirect.com/collections/ne-obliviscaris"
          target="_blank"
          rel="noreferrer"
        >
          <button>SHOP NORTH AMERICAN STORE</button>
        </a>
        <a
          href="https://artistfirst.com.au/collections/ne-obliviscaris"
          target="_blank"
          rel="noreferrer"
        >
          <button>SHOP AUSTRALIAN STORE</button>
        </a>
        <a
          href="https://www.impericon.com/de/ne-obliviscaris.html"
          target="_blank"
          rel="noreferrer"
        >
          <button>SHOP EUROPEAN STORE</button>
        </a>
      </div>
      <div className={styles.Tours}>
        {/* <section className={styles.SummerFestivals}>
          <div className={styles.Text}>
            <h1>SUMMER FESTIVALS 2024</h1>
            <small>JUN - JUL 2024</small>
            <small>
              FESTIVAL APPEARANCES ACROSS BOTH
              <strong style={{ color: "#7BB6BD" }}>
                <i> EUROPE</i>
              </strong>{" "}
              AND THE{" "}
              <strong style={{ color: "#7BB6BD" }}>
                <i> UNITED KINGDOM</i>
              </strong>{" "}
            </small>
            <div style={{ maxWidth: "300px" }}>
              <button
                className="cta"
                onClick={() => {
                  navigate("/tours/summer-festivals-2024");
                }}
              >
                GET TICKETS
              </button>
            </div>
          </div>
        </section> */}
      </div>
      {/* <div ref={tourRef}>
        <Tour tourPath="tours-2024" name="Upcoming Tours" />
      </div> */}
      <section
        className={styles.Patreon}
        style={{ backgroundImage: "url('https://i.imgur.com/10icRmp.png')" }}
      >
        <div className={styles.Text}>
          <h1>
            JOIN THE <br />
            NE OBLUMINATI
          </h1>
          <small>
            EARLY ACCESS{" "}
            <span style={{ color: "#BD2C45", fontWeight: "700" }}>|</span> VIP
            TICKETS{" "}
            <span style={{ color: "#BD2C45", fontWeight: "700" }}>|</span>{" "}
            EXCLUSIVE CONTENT
          </small>
          <a href="https://www.patreon.com/neobliviscaris/posts">
            <img
              src="https://img.icons8.com/ios-filled/150/FFFFFF/patreon.png"
              alt="Go to Ne Obliviscaris patreon page"
              width="50%"
              className={styles.PatreonLogo}
            />
          </a>
        </div>
      </section>
      <section
        className={`${styles.Single} ${styles.NewSingle}`}
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)),url('https://i.imgur.com/ywaoSyz.png')",
        }}
      >
        <div className={styles.Text}>
          <div>
            <small>NEW SINGLE & VIDEO</small>
            <h1>'GRAAL'</h1>
          </div>
          <a
            href="https://www.youtube.com/watch?v=WAnq3cAhqDA"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.imgur.com/oBrtQP8.png"
              alt="play equus music video"
            />
          </a>
        </div>
        <div className={styles.Stream}>
          <a href="/music/single/graal">
            <button className={styles.LightButton}>STREAM NOW</button>
          </a>
        </div>
      </section>
      <section
        className={styles.Single}
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url('https://i.imgur.com/jBIag4b.png')",
        }}
      >
        <div className={styles.Text}>
          <div>
            <small>NEW SINGLE & VIDEO</small>
            <h1>'EQUUS'</h1>
          </div>
          <a
            href="https://www.youtube.com/watch?v=Bftqq_BqQHI"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.imgur.com/oBrtQP8.png"
              alt="play equus music video"
            />
          </a>
        </div>
        <div className={styles.Stream}>
          <a href="/music/single/equus">
            <button className={styles.LightButton}>STREAM NOW</button>
          </a>
        </div>
      </section>
    </div>
  );
}

export default Home;

const StoreOptionsSelect = styled("div")(() => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  rowGap: "16px",
  justifyContent: "center",
  alignItems: "center"
}));
