import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface PrimaryButtonProps{
  children?: any;
  onClick?: (e: any) => any;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({ children, onClick }) => {
  return <StyledPrimaryButton onClick={(e) => onClick?.(e)}>{children}</StyledPrimaryButton>;
};

const StyledPrimaryButton = styled.button`
  border: 2px solid #7bb6bd;
  background-color: transparent;
  color: #7bb6bd;
  width: 100%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  padding: 8px 16px;
  width: auto;
`;

export default PrimaryButton;
